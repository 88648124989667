class Enum {
  constructor (value, label = null) {
    this.value = value
    this.label = label || value
  }

  static all () {
    return Object.keys(this).map(key => this[key])
  }
}

export default Enum
