var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('button',{class:{
      'text-grey-darker font-bold border border-grey bg-white': null === _vm.value,
      'bg-grey-f9 text-grey-semi-light': null !== _vm.value,
      'rounded-full py-1 px-3 mr-1 flex items-center text-xs': true
    },on:{"click":function($event){return _vm.handleClick(null)}}},[_vm._v(" All ")]),_vm._l((_vm.workflows),function(workflow){return _c('button',{key:workflow.id,class:{
      'text-grey-darker font-bold border-grey bg-white': workflow.id === _vm.value,
      'bg-grey-f9 text-grey-semi-light border-grey-f9': workflow.id !== _vm.value,
      'rounded-full py-1 pl-1 pr-2 mr-1 flex items-center text-xs border': true
    },on:{"click":function($event){return _vm.handleClick(workflow.id)}}},[_c('div',{staticClass:"w-4 h-4 rounded-full mr-1.5",style:(`background-color: ${workflow.color}`)}),_c('span',[_vm._v(_vm._s(workflow.name))])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }