import Enum from './Enum'

class Phase extends Enum {
  static INQUIRY = new Phase('inquiry', 'Inquiry')
  static PROPOSAL = new Phase('proposal', 'Proposal')
  static BOOKED = new Phase('booked', 'Booked')
  static POST_PRODUCTION = new Phase('post-production', 'Post Production')
  static FINISHED = new Phase('finished', 'Finished')
}

export default Phase
