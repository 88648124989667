import { client } from './VOClient'

/**
 * Create a workflow
 *
 * @param data
 * @param {string} data.name
 * @param {string} data.color
 */
export const createWorkflow = (data) => {
  return client.post('workflows', data)
}

/**
 * Duplicate a workflow
 * @param id
 */
export const duplicateWorkflow = (id) => {
  return client.post(`workflows/${id}/duplicate`)
}

/**
 * Update a workflow
 *
 * @param {number} id
 * @param data
 * @param {string} data.name
 * @param {string} data.color
 */
export const updateWorkflow = (id, data) => {
  return client.put(`workflows/${id}`, data)
}

export const getWorkflows = (page = 1, perPage = null) => {
  // Add a huge per_page number because for some reason the pagination happens client-side
  // so we need to retrieve all the workflows
  return client.get('workflows', {
    page: page,
    per_page: perPage || Number.MAX_SAFE_INTEGER
  })
}

export const getWorkflow = (id) => {
  return client.get(`workflows/${id}`)
}

export const deleteWorkflow = (id) => {
  return client.delete(`workflows/${id}`)
}
