<template>
  <progress-dots
    :total="total"
    :progress="progress"
    :tooltips="tooltips"
  />
</template>

<script>
import ProgressDots from './ProgressDots'
export default {
  name: 'ActionProgress',
  components: { ProgressDots },
  props: {
    actions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    total() {
      return this.actions.length
    },
    progress() {
      return this.actions.filter(a => a.status === 'completed').length
    },
    tooltips() {
      return [...this.actions].sort((a, b) => a.status === 'completed' ? -1 : 0).map(a => a.name)
    }
  }
}
</script>

<style scoped>

</style>
