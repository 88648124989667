<template>
  <div class="flex">
    <button
      :class="{
        'text-grey-darker font-bold border border-grey bg-white': null === value,
        'bg-grey-f9 text-grey-semi-light': null !== value,
        'rounded-full py-1 px-3 mr-1 flex items-center text-xs': true
      }"
      @click="handleClick(null)"
    >
      All
    </button>
    <button
      v-for="workflow in workflows"
      :key="workflow.id"
      :class="{
        'text-grey-darker font-bold border-grey bg-white': workflow.id === value,
        'bg-grey-f9 text-grey-semi-light border-grey-f9': workflow.id !== value,
        'rounded-full py-1 pl-1 pr-2 mr-1 flex items-center text-xs border': true
      }"
      @click="handleClick(workflow.id)"
    >
      <div
        class="w-4 h-4 rounded-full mr-1.5"
        :style="`background-color: ${workflow.color}`"
      />
      <span>{{ workflow.name }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'WorkflowFilter',
  emits: ['change'],
  props: {
    workflows: {
      type: Array,
      required: true
    },
    value: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selected: null
    }
  },
  methods: {
    handleClick(value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style scoped>

</style>
