<style lang="scss">
  .intro-title{
    position: relative;
    padding:0 30px;
  }

  .left-celebrate{
    position:absolute;
    left:10px;
    top:-40px;
  }
  .right-celebrate{
    position:absolute;
    right: -15px;
    top: -43px;
  }
</style>
<template>
  <div id="projects-overview">
    <vue-headful
      title="Overview project activities | Octoa"
    />

    <v-video-modal
      name="intro"
      title="Welcome to Octoa!"
      description="A video to help you get started."
      step="intro"
      thumbnail="intro.png"
      embed="2JIHA--ftno"
      :show.sync="isShowIntroModal"
      @close="closeIntroModal"
    />

    <v-video-modal
      name="overview"
      title="Project overview"
      description="A video to help you get started."
      step="projects-overview"
      thumbnail="intro.png"
      embed="vbilmn82hp8"
      :show.sync="isShowProjectOverviewModal"
      @close="closeProjectOverviewModal"
    />

    <modal
      name="getStartedModal"
      class="modal full-screen"
      width="100%"
      :max-width="700"
      height="auto"
      :adaptive="true"
    >
      <div class="content">
        <div class="flex px-2 justify-between">
          <div>
            <span class="title">Learn by doing: Create a lead</span>
            <p class="mt-2 text-grey-darker leading-normal bg-blue-washed rounded px-4 py-3">
              A big time-saver is to embed an Octoa lead form into your website. Step into the role of a potential client by start filling in the lead form.
            </p>
          </div>
          <div
            class="close "
            @click="$modal.hide('getStartedModal')"
          />
        </div>
        <v-spinner
          class="my-10"
          :show="leadFormLoading"
        />
        <form
          v-if="!leadFormLoading"
          id="lead"
          class="mt-5 border-none"
          @submit.prevent="saveLead"
        >
          <div
            v-for="(field, index) in form.fields"
            :key="field.id"
            class="container mx-auto px-2 items-start content-start flex mb-5"
          >
            <div class="w-full ">
              <label class="block mb-2">
                {{ field.label }} <span
                  v-if="field.is_required"
                  class="float-right text-grey"
                >Required </span>
              </label>
              <input
                v-if="field.type == 'text' && field.mapping == 'email'"
                v-model.lazy="field.value"
                v-validate="{ required: field.is_required, email: true }"
                class="w-full lead"
                type="text"
                :name="field.name"
              >
              <input
                v-if="field.type == 'text' && field.mapping != 'email'"
                v-model="field.value"
                v-validate="{ required: field.is_required }"
                class="w-full lead"
                type="text"
                :name="field.name"
              >
              <textarea
                v-if="field.type == 'textarea'"
                v-model="field.value"
                v-validate="{ required: field.is_required }"
                :name="field.name"
                @input="form.fields[index].value = $event.target.value"
              />
              <v-date-picker
                v-if="field.type == 'datepicker'"
                v-model="field.value"
                v-validate="{ required: true }"
                class="datepicker"
                :name="field.name"
                :popover="{ placement: 'top', visibility: 'focus' }"
                :masks="{L: 'WWW, MMM D, YYYY'}"
                :input-props="{
                  readonly: true
                }"
              />
              <div
                v-if="errors.first(`${field.name}`)"
                class="error mt-1"
              >
                {{ errors.first(`${field.name}`) }}.
              </div>
            </div>
          </div>
          <div
            class="w-full max-w-lg mx-auto mt-2 mb-2 text-right pr-2"
          >
            <input
              type="submit"
              name="submit"
              value="Submit"
              class="green-btn"
            >
          </div>
        </form>
      </div>
    </modal>
    <modal
      name="createProject"
      class="modal overflow-visible"
      width="100%"
      :max-width="700"
      height="auto"
      :adaptive="true"
    >
      <div class="modal-content-wrapper">
        <div class="flex px-2 justify-between">
          <div class="title">
            Create a new project
          </div>
          <div
            class="close "
            @click="$modal.hide('createProject')"
          />
        </div>

        <div class="flex px-2 mt-2">
          <div class="input-group w-full">
            <label>Give your project a title</label>
            <input
              v-model="projectName"
              v-validate="{ required: true }"
              type="text"
              name="projectName"
              class="w-full"
            >
            <span
              v-if="errors.has('projectName')"
              class="error"
            >A project name is required.</span>
          </div>
        </div>

        <div class="flex flex-wrap mt-2 justify-between">
          <div
            class="w-full  px-2"
            :class="[!noProjects ? 'md:w-2/3' : '']"
          >
            <div class="w-full input-group block">
              <label v-if="!isNewContact">Search for a contact</label>
              <autocomplete
                v-if="!isNewContact"
                :search="searchContact"
                :get-result-value="getContactResult"
                :debounce-time="500"
                @submit="searchContactClick"
              />
              <span
                v-if="hasError && !isNewContact && !selectedContact"
                class="error"
              >Please search and select an existing contact.</span>
              <div v-if="isNewContact">
                <div class="input-group">
                  <label class="text-base">Client's full name</label>
                  <input
                    v-model="fullName"
                    v-validate="{ required: true }"
                    type="text"
                    class="w-full"
                    name="Full name"
                  >
                  <span
                    v-if="errors.has('Full name')"
                    class="error"
                  >{{ errors.first('Full name') }}.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-2">
            <a
              v-show="!noProjects"
              class="md:mt-10 green underline cursor-pointer mt-2 mb-3 md:mb-0 md:ml-3 block"
              @click="toggleContact"
            >
              <span v-if="!isNewContact">Add a new contact</span>
              <span v-if="isNewContact">Search a contact by name</span>
            </a>
          </div>
        </div>
        <div
          v-if="isNewContact"
          class="flex mt-2 justify-between items-center"
        >
          <div class="w-full px-2">
            <div class="input-group">
              <label>Client's email</label>
              <input
                v-model.lazy.trim="email"
                v-validate="{ required: true, email: true }"
                type="text"
                class="w-full"
                name="email"
                @blur="checkEmail"
              >
              <span
                v-if="errors.has('email')"
                class="error"
              >{{ errors.first('email') }}.</span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-2 items-top">
          <div class="w-full md:w-1/2 px-2">
            <div class="input-group">
              <div class="flex justify-between items-center">
                <label>Project date</label>
                <div class="flex items-center">
                  <v-checkbox
                    id="multi-days"
                    v-model="multiDays"
                    name="multi-days"
                    :checked="multiDays"
                    label="Multi-day"
                  />
                </div>
              </div>
              <v-date-picker
                v-if="!multiDays"
                v-model="projectDate"
                class="datepicker"
                :popover="{ visibility: 'click' }"
                :masks="{L: 'WWW, MMM D, YYYY'}"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                  >
                </template>
              </v-date-picker>
              <div
                v-if="multiDays"
                class="flex"
              >
                <v-date-picker
                  v-model="projectDate"
                  class="datepicker mr-2"
                  :popover="{ visibility: 'click' }"
                  :masks="{L: 'WWW, MMM D, YYYY'}"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      placeholder="Start date"
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                  </template>
                </v-date-picker>
                <v-date-picker
                  v-model="projectEndDate"
                  class="datepicker"
                  :popover="{ visibility: 'click' }"
                  :masks="{L: 'WWW, MMM D, YYYY'}"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      placeholder="End date"
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                  </template>
                </v-date-picker>
              </div>
              <span
                v-if="projectDateError"
                class="error"
              >
                {{ projectDateError }}
              </span>
            </div>
          </div>
          <div class="w-full md:w-1/2 px-2 mt-3 md:mt-0">
            <div class="input-group block">
              <label>Add tags (comma separated)</label>
              <vue-tags-input
                v-model="tag"
                placeholder=""
                :tags="tags"
                :autocomplete-items="autocompleteTagItems"
                :add-on-key="[188]"
                @tags-changed="updateTags"
              />
            </div>
          </div>
        </div>
        <div class="flex mt-2">
          <div class="w-1/2 px-2">
            <div
              class="input-group block"
            >
              <label>Choose workflow <v-help-tooltip
                class="absolute"
                :options="workflowContent"
              /></label>
              <div class="select-container w-full">
                <select
                  v-model="selectedWorkflow"
                  v-validate="{ required: true }"
                  name="selectedWorkflow"
                >
                  <option :value="null">Select a workflow</option>
                  <option
                    v-for="workflow in workflows"
                    :key="workflow.id"
                    :value="workflow.id"
                  >
                    {{ workflow.name }}
                  </option>
                </select>
                <span
                  v-if="errors.has('selectedWorkflow')"
                  class="error"
                >A workflow is required.</span>
              </div>
            </div>
          </div>
          <div class="flex w-1/2 items-center flex-wrap">
            <div
              class="w-1/2 px-2"
              :class="{
                'w-1/2': multiCurrencies === 1,
                'w-full': multiCurrencies !== 1
              }"
            >
              <div
                class="input-group block w1/2"
              >
                <label>Language <v-help-tooltip
                  class="absolute"
                  :options="languageContent"
                /></label>
                <div class="select-container w-full">
                  <select v-model="projectLanguage">
                    <option
                      v-for="language in createProjectLanguages"
                      :key="language.code"
                      :value="language.code"
                    >
                      {{ language.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="w-1/2 px-2">
              <div
                v-show="multiCurrencies == 1"
                class="input-group block"
              >
                <label>Currency</label>
                <div class="select-container w-full">
                  <select v-model="projectCurrency">
                    <option
                      v-for="currency in currencies"
                      :key="currency.id"
                      :value="currency.id"
                    >
                      {{ currency.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap items-center mt-2"
        >
          <div class="w-1/2 px-2 " />
          <div class="w-1/2 px-2 text-center md:text-right">
            <button
              class="green-btn w-full mb-10"
              @click="createProject"
            >
              Get started
            </button>
          </div>
        </div>
      </div>
    </modal>

    <v-spinner :show="loading" />
    <div
      v-show="!loading"
      class="md:pb-0"
    >
      <div v-if="!noProjects">
        <div class="sub-header">
          <div class="mx-auto max-w-2xl px-4">
            <div class="hidden lg:block">
              <div class="flex items-center flex-grow mr-0">
                <div class="flex-grow wrapper mr-0">
                  <div class="w-full flex">
                    <div class="w-full">
                      <div class="flex">
                        <div
                          class="status right first flex-grow "
                          :class="{'active' : viewFilter === 'active'}"
                          @click="toggleFilter('active')"
                        >
                          <div class="bg">
                            <span class="number">{{ activeProjects }}</span> All active projects
                          </div>
                        </div>

                        <div
                          v-for="phase in phases"
                          :key="phase.value"
                          class="status right left flex-grow"
                          :class="{'active' : viewFilter === phase.value}"
                          @click="toggleFilter(phase.value)"
                        >
                          <div class="bg">
                            <span class="number">{{ phaseCounts[phase.value] }}</span> {{ phase.label }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-grow last mr-0">
                  <div
                    class="status dark"
                    :class="{'active' : viewFilter === 'archived'}"
                    @click="toggleFilter('archived')"
                  >
                    <div class="bg rounded">
                      {{ archivedProjects }} Archived
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block lg:hidden">
              <div class="select-container w-full my-5">
                <select
                  v-model="viewFilter"
                  @change="changeFilter"
                >
                  <option
                    value="active"
                  >
                    All active projects
                  </option>
                  <option
                    v-for="phase in phases"
                    :key="phase.value"
                    :value="phase.value"
                  >
                    {{ phase.label }}
                  </option>
                  <option
                    value="archived"
                  >
                    Archived
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:mt-6 px-4 mx-auto max-w-2xl">
          <div class="mt-1 md:mt-3 lg:mt-6 flex flex-wrap items-center justify-between">
            <div class="mb-3 lg:mb-0">
              <h1 class="mt-2 md:mt-3 lg:mt-0 text-black text-xl">
                Your projects
              </h1>
            </div>
            <div class="flex w-full md:w-auto">
              <div class="mr-5 z-10">
                <v-tutorial-button @click="showProjectOverviewModal" />
              </div>
              <div
                class="flex items-center flex-grow md:flex-no-grow green-plus-btn"
                @click="showCreateProjectModal"
              >
                <div class="plus">
                  <img
                    src="@/assets/img/icons/ico-plus.svg"
                    class="align-middle"
                  >
                </div>
                <div class="flex-grow">
                  <button>
                    Create new project
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="border-b border-grey-lighter py-4">
            <workflow-filter
              :workflows="workflows"
              :value="filterWorkflow"
              @change="handleWorkflowFilter"
            />
          </div>

          <div v-if="!noProjects">
            <div class="hidden lg:block">
              <div class="filter-date flex items-center mt-5">
                <div class="select-container">
                  <select
                    v-model="filterMonth"
                    @change="refreshDataTable"
                  >
                    <option value="all">
                      All months
                    </option>
                    <option value="1">
                      Jan
                    </option>
                    <option value="2">
                      Feb
                    </option>
                    <option value="3">
                      Mar
                    </option>
                    <option value="4">
                      Apr
                    </option>
                    <option value="5">
                      May
                    </option>
                    <option value="6">
                      Jun
                    </option>
                    <option value="7">
                      Jul
                    </option>
                    <option value="8">
                      Aug
                    </option>
                    <option value="9">
                      Sep
                    </option>
                    <option value="10">
                      Oct
                    </option>
                    <option value="11">
                      Nov
                    </option>
                    <option value="12">
                      Dec
                    </option>
                  </select>
                </div>
                <div class="select-container ml-4">
                  <select
                    v-model="filterYear"
                    @change="refreshDataTable"
                  >
                    <option value="all">
                      All years
                    </option>
                    <option value="lastYear">
                      {{ lastYear }}
                    </option>
                    <option value="current">
                      {{ thisYear }}
                    </option>
                    <option value="next">
                      {{ nextYear }}
                    </option>
                    <option value="next2Year">
                      {{ next2Year }}
                    </option>
                  </select>
                </div>
              </div>
              <v-server-table
                ref="datatable"
                class="project-overview mt-5"
                :columns="columns"
                :options="options"
                @row-click="goDetail"
                @sorted="onSorted"
              >
                <div
                  slot="date"
                  slot-scope="props"
                >
                  {{ formattedDate(props.row) }}
                  <span
                    v-if="props.row.is_double_date_warning"
                    v-tooltip="{ ...tooltip, content: 'You have a double booking on this date.' }"
                    class="float-right mr-2"
                  >
                    <img src="@/assets/img/icons/ico-alert-orange.svg">
                  </span>
                </div>
                <div
                  slot="name"
                  slot-scope="props"
                >
                  <a
                    :id="`project-${props.row.id}`"
                    class="hover:green"
                    :href="`/projects/${props.row.id}`"
                  >
                    {{ props.row.name }}
                  </a>
                </div>
                <div
                  slot="created_at"
                  slot-scope="props"
                >
                  <span>
                    {{ props.row.created_at | timeFromNowSimple }} ago
                  </span>
                </div>
                <div
                  slot="state"
                  slot-scope="props"
                >
                  <div v-if="props.row.state == 'paid'">
                    Deposit paid
                  </div>
                  <div v-else>
                    {{ props.row.state | toUpperCaseFormat }}
                  </div>
                </div>
                <div
                  v-if="props.row.tags"
                  slot="tags"
                  slot-scope="props"
                  class="tags"
                >
                  <span
                    v-for="(tag, index) in props.row.tags"
                  >
                    <span
                      v-if="index < 5"
                      class="tag"
                    >{{ tag.name.en }}</span>
                  </span>
                  <span
                    v-if="props.row.tags.length > 5"
                    class="tag"
                  >
                    ...
                  </span>
                </div>
                <div
                  slot="actions"
                  slot-scope="props"
                >
                  <action-progress :actions="props.row.phase_actions" />
                </div>
              </v-server-table>
            </div>

            <div class="mb-12 block lg:hidden">
              <div class="filter-date flex justify-end mt-5">
                <div class="select-container">
                  <select
                    v-model="filterMonth"
                    @change="refreshDataTable"
                  >
                    <option value="all">
                      All months
                    </option>
                    <option value="1">
                      Jan
                    </option>
                    <option value="2">
                      Feb
                    </option>
                    <option value="3">
                      Mar
                    </option>
                    <option value="4">
                      Apr
                    </option>
                    <option value="5">
                      May
                    </option>
                    <option value="6">
                      Jun
                    </option>
                    <option value="7">
                      Jul
                    </option>
                    <option value="8">
                      Aug
                    </option>
                    <option value="9">
                      Sep
                    </option>
                    <option value="10">
                      Oct
                    </option>
                    <option value="11">
                      Nov
                    </option>
                    <option value="12">
                      Dec
                    </option>
                  </select>
                </div>
                <div class="select-container ml-4">
                  <select
                    v-model="filterYear"
                    @change="refreshDataTable"
                  >
                    <option value="all">
                      All years
                    </option>
                    <option value="current">
                      {{ thisYear }}
                    </option>
                    <option value="next">
                      {{ nextYear }}
                    </option>
                    <option value="next2Year">
                      {{ next2Year }}
                    </option>
                  </select>
                </div>
              </div>
              <v-server-table
                ref="mobileDatatable"
                class="mobile-project-overview"
                :columns="mobileColumns"
                :options="moblieOptions"
                @row-click="goDetail"
                @sorted="onSorted"
              >
                <div
                  slot="date"
                  slot-scope="props"
                >
                  <div
                    class="font-bold block break-words"
                    style="min-height:34px;"
                  >
                    {{ props.row.name }}
                  </div>
                  <div class="mt-4 text-xs text-grey-semi-light">
                    Project date
                  </div>
                  {{ formattedDate(props.row) }}
                  <span
                    v-if="props.row.is_double_date_warning"
                    v-tooltip="{ ...tooltip, content: 'You have a double booking on this date.' }"
                    class="ml-2"
                  >
                    <img src="@/assets/img/icons/ico-alert-orange.svg">
                  </span>
                </div>
                <div
                  slot="created_at"
                  slot-scope="props"
                >
                  <div class="text-blue ">
                    {{ props.row.state | toUpperCaseFormat }}
                  </div>
                  <div class="mt-8 text-xs text-grey-semi-light">
                    Created
                  </div>
                  <div>
                    {{ props.row.created_at | timeFromNowSimple }} ago
                  </div>
                </div>
              </v-server-table>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="pb-10"
      >
        <div class="flex max-w-xl mx-auto pt-32 text-center justify-center">
          <div class="w-full px-4">
            <div class="py-8 px-4 rounded">
              <h1 class="intro-title text-3xl font-bold">
                <span class="left-celebrate">
                  <img
                    src="@/assets/img/icons/ico-left-celebrate.svg"
                    width="57"
                  >
                </span>Hi {{ user.me.first_name }}, you're now officially an Octoan. Let's get started!<span class="right-celebrate"><img
                  src="@/assets/img/icons/ico-right-celebrate.svg"
                  width="57"
                ></span>
              </h1>
              <p class="text-base max-w-md text-center leading-normal mx-auto my-10">
                Octoa is packed with features, but let's get started with creating your first project and pick it up from there.
              </p>
              <button
                class="green-btn "
                @click="showCreateProjectModal"
              >
                <span class="px-10">
                  Create project
                </span>
              </button>
            </div>
          </div>
          <!-- <div class="w-1/2 px-4">
            <div class="py-10 bg-grey-light border-grey-e3 border rounded">
              <h2 class="font-bold">
                Finalize your account
              </h2>
              <p class="text-base my-5">
                Upload your logo and add bank details.
              </p>
              <router-link
                to="/settings"
                class="inline-block  green-btn text-white"
              >
                Complete your account
              </router-link>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import filter from 'lodash.filter'
import { format } from 'date-fns'
import { convertToTimeZone } from 'date-fns-timezone'
import { logException } from '@/helpers'
import { createCancelSource } from '@/http'
import storage from '@/storage'
import auth from '@/auth'
import { getWorkflows } from '../api/Workflows'
import Phase from '../Enums/Phase'
import ProgressDots from '../components/ProgressDots'
import ActionProgress from '../components/ActionProgress'
import WorkflowFilter from '../components/WorkflowFilter'

let cancelSource

export default {
  name: 'Projects',
  components: { WorkflowFilter, ActionProgress, ProgressDots },
  data () {
    return {
      loading: true,
      hasError: false,
      isNewContact: true,
      selectedContact: null,
      currencies: null,
      contact: '',
      tag: '',
      tags: [],
      autocompleteTagItems: [],
      projectName: '',
      projectDate: null,
      projectEndDate: null,
      projectCurrency: null,
      projectLanguage: 'en',
      noProjects: true,
      fullName: '',
      email: '',
      activeProjects: 0,
      phaseCounts: {},
      emailError: false,
      doneLeadForm: false,
      doneProposal: false,
      doneContract: false,
      doneIntro: false,
      user: auth.user(),
      form: {},
      leadFormLoading: true,
      leadFormToken: null,
      isShowIntroModal: false,
      isShowProjectOverviewModal: false,
      timezone: '',
      multiCurrencies: 0,
      sortAscending: 0,
      viewFilter: 'active',
      orderBy: 'created_at',
      filterYear: 'all',
      multiDays: false,
      projectDateError: false,
      filterMonth: 'all',
      filterWorkflow: null,
      tooltip: {
        boundariesElement: '.table-responsive',
        delay: 0,
        show: false,
        offset: 0,
        trigger: 'hover',
        classes: ['header-tooltip', 'normal-case'],
        placement: 'bottom',
        defaultHideOnTargetClick: false,
        autoHide: false
      },
      workflowContent: {
        content: ''
      },
      languageContent: {
        content: 'The language to display on the client view for your proposals and invoices.'
      },
      columns: ['date', 'name', 'created_at', 'state', 'actions', 'tags'],
      mobileColumns: ['date', 'created_at'],
      options: {
        requestAdapter: (data) => {
          data.companyId = this.user.company.id
          data.ascending = this.sortAscending
          data.orderBy = this.orderBy
          data.filter = this.viewFilter
          data.filterYear = this.filterYear
          data.filterMonth = this.filterMonth
          data.filterWorkflow = this.filterWorkflow
          return data
        },
        async requestFunction (data) {

          storage.set('projectTableSaved', 1)
          storage.set('projectTableSortAscending', data.ascending)
          storage.set('projectTableOrderBy', data.orderBy)
          storage.set('projectTableFilter', data.filter)
          storage.set('projectTableFilterYear', data.filterYear)
          storage.set('projectTableFilterMonth', data.filterMonth)

          const response = await this.$api.get('projects').list(data)
          return response.data
        },
        headings: {
          date: 'Project date',
          name: 'Name',
          created_at: 'Created',
          state: 'Phase',
          tags: '',
        },
        orderBy: { column: 'created_at', ascending: false },
        sortable: ['date', 'name', 'created_at'],
        descOrderColumns: ['date', 'created_at'],
        perPage: 25,
        perPageValues: [25, 50, 75, 100],
        serverMultiSorting: true,
        texts: {
          filterPlaceholder: 'Search project or contact',
          noResults: 'No projects found',
        },
        rowAttributesCallback: (row) => {
          return {
            'data-color': row.workflow.color.replace('#', ''),
            style: `--row-color: ${row.workflow.color}`
          }
        }
      },
      moblieOptions: {
        requestAdapter: (data) => {
          data.companyId = this.user.company.id
          data.ascending = this.sortAscending
          data.orderBy = this.orderBy
          data.filter = this.viewFilter
          data.filterYear = this.filterYear
          data.filterMonth = this.filterMonth
          data.filterWorkflow = this.filterWorkflow
          return data
        },
        async requestFunction (data) {

          storage.set('projectTableSaved', 1)
          storage.set('projectTableSortAscending', data.ascending)
          storage.set('projectTableOrderBy', data.orderBy)
          storage.set('projectTableFilter', data.filter)
          storage.set('projectTableFilterYear', data.filterYear)
          storage.set('projectTableFilterMonth', data.filterMonth)

          const response = await this.$api.get('projects').list(data)
          return response.data
        },
        headings: {
          date: 'Project date',
          created_at: 'Created',
        },
        orderBy: { column: 'created_at', ascending: false },
        sortable: ['date', 'created_at'],
        descOrderColumns: ['date', 'created_at'],
        perPage: 25,
        perPageValues: [25, 50, 75, 100],
        serverMultiSorting: true,
        texts: {
          filterPlaceholder: 'Search project or contact',
          noResults: 'No projects found',
        },
      },
      workflows: [],
      selectedWorkflow: null,
      phases: Phase.all(),
    }
  },
  computed: {
    lastYear () {
      let year = new Date().getFullYear()
      let lastYear = year - 1
      return lastYear
    },
    thisYear () {
      let year = new Date().getFullYear()
      return year
    },
    nextYear () {
      let year = new Date().getFullYear()
      let nextyear = year + 1
      return nextyear
    },
    next2Year () {
      let year = new Date().getFullYear()
      let next2Year = year + 2
      return next2Year
    },
    createProjectLanguages() {
      const languages = filter(this.user.companySettings.locales, (language => language.isActive))
      return languages
    }
  },
  watch: {
    'tag': 'initItems',
  },
  created () {
    if (storage.has('projectTableSaved')) {
      this.sortAscending = storage.get('projectTableSortAscending')
      this.orderBy = storage.get('projectTableOrderBy')
      this.viewFilter = storage.get('projectTableFilter')
      this.filterYear = storage.get('projectTableFilterYear')
      this.filterMonth = storage.get('projectTableFilterMonth')
    }

    if (this.user.company === undefined) {
      this.$router.push('/register/company')
    }
  },
  async mounted () {
    this.timezone = this.user.companySettings.timezone
    this.projectCurrency = this.user.companySettings.currency.id
    this.multiCurrencies = parseInt(this.user.companySettings.multiCurrencies)
    this.projectLanguage = this.user.companySettings.defaultLanguage

    try {
      const { data } = await this.$api.get('currency').list()
      this.currencies = data.currencies
    } catch (e) {
      this.$toasted.global.api_error(e)
      logException(e)
    }

    await this.getData()
    // if (this.noProjects) {
    //   //await this.checkIntroVideo()
    // } else {
    //   await this.checkIntroOverviewVideo()
    // }
  },
  methods: {
    formattedDate(project) {
      if (!project.date) return '-'

      const startDate = new Date(project.date)
      const startDay = startDate.toLocaleString('default', { day: 'numeric', timeZone: 'CET' })
      const startMonth = startDate.toLocaleString('default', { month: 'short', timeZone: 'CET' })
      const startYear = startDate.toLocaleString('default', { year: 'numeric', timeZone: 'CET' })

      if (project.end_date && project.end_date !== project.date) {
        const endDate = new Date(project.end_date)
        const endDay = endDate.toLocaleString('default', { day: 'numeric', timeZone: 'CET' })
        const endMonth = endDate.toLocaleString('default', { month: 'short', timeZone: 'CET' })
        const endYear = endDate.toLocaleString('default', { year: 'numeric', timeZone: 'CET' })

        // Check if the start and end dates are in the same year
        if (startYear === endYear) {
          // Further check if they are in the same month
          if (startMonth === endMonth) {
            return `${startMonth} ${startDay}-${endDay}, ${startYear}`
          } else {
            return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`
          }
        } else {
          // Handle different years
          return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`
        }
      } else {
        return `${startMonth} ${startDay}, ${startYear}`
      }
    },
    handleWorkflowFilter (workflow) {
      this.filterWorkflow = workflow
      this.refreshDataTable()
    },
    onSorted (data) {
      this.orderBy = data.column
      this.sortAscending = (data.ascending) ? 1 : 0
    },
    goDetail (data) {
      this.$router.push('/projects/' + data.row.id)
    },
    async checkEmail () {
      this.emailError = false
      try {
        const { data } = await this.$api.get('contacts').checkEmail({ contactEmail: this.email })

        if (data.contact) {
          this.emailError = true
          this.errors.add({
            field: 'email',
            msg: 'This email address is already in use.'
          })
        }
      } catch (e) {
        logException(e)
      }
    },
    refreshDataTable () {
      this.$refs.mobileDatatable.refresh()
      this.$refs.datatable.refresh()
    },
    changeFilter (target) {
      this.$router.push({ name: 'Projects', query: { filter: this.viewFilter } })
      this.refreshDataTable()
    },
    toggleFilter (target) {
      if (this.viewFilter != target) {
        this.viewFilter = target
        this.filterYear = 'all'
        this.filterMonth = 'all'
        this.filterWorkflow = null
        this.$router.push({ name: 'Projects', query: { filter: target } })
        this.refreshDataTable()
      }
    },
    toggleContact () {
      this.selectedContact = null
      if (this.isNewContact) {
        this.isNewContact = false
      } else {
        this.isNewContact = true
      }
    },
    handleSelect (item) {
      this.selectedName = item.first_name + ' ' + item.last_name
      this.chosen = item.id
    },
    showCreateProjectModal () {
      this.$modal.show('createProject')
    },
    updateTags (newTags) {
      this.autocompleteTagItems = []
      this.tags = newTags
    },
    createProject () {
      this.hasError = false
      this.$validator.validate().then(result => {
        // if this.multiDays is true, then projectEndDate is required
        if (this.multiDays && !this.projectEndDate) {
          this.hasError = true
          this.projectDateError = 'End date is required when extended event is checked.'
          return false
        }

        // if this.multiDays is true, then projectEndDate cannot be before projectDate
        if (this.multiDays && this.projectEndDate && this.projectEndDate < this.projectDate) {
          this.hasError = true
          this.projectDateError = 'End date cannot be before start date.'
          return false
        }

        if (!this.emailError && !this.isNewContact && !this.selectedContact) {
          this.hasError = true
          return false
        }

        if (result) {
          this.create()
        }
      })
    },
    async create () {
      cancelSource = createCancelSource()

      let date = (this.projectDate) ? format(this.projectDate, 'YYYY-MM-DD') : null
      if (date) {
        try {
          const { data } = await this.$api.get('projects').checkDate({ date: date })
          if (data.status == 'exist') {
            const canGo = await this.$alert.confirm({
              title: 'Oops, it looks like another project is already on this date.',
              text: 'Are you sure you want to continue?',
            })
            if (canGo) {
              await this.saveProject()
            }
          } else {
            await this.saveProject()
          }
        } catch (e) {
          logException(e)
        }
      } else {
        await this.saveProject()
      }
    },
    async saveProject () {

      cancelSource = createCancelSource()

      let date = (this.projectDate) ? format(this.projectDate, 'YYYY-MM-DD') : null
      let endDate = (this.projectEndDate && this.multiDays) ? format(this.projectEndDate, 'YYYY-MM-DD') : null

      const input = {
        isNewContact: this.isNewContact,
        contact: this.selectedContact,
        projectName: this.projectName,
        projectDate: date,
        projectEndDate: endDate,
        projectCurrency: this.projectCurrency,
        projectLanguage: this.projectLanguage,
        contactFullName: this.fullName,
        contactEmail: this.email,
        tags: this.tags,
        workflowId: this.selectedWorkflow,
      }

      try {
        const { data } = await this.$api.get('projects').create(input, {
          cancelToken: cancelSource.token
        })

        const postData = {
          entryType: 'add_first_project',
        }

        try {
          await this.$store.dispatch('company/gamification', postData)
        } catch (e) {
          this.$toasted.global.api_error(e)
        }

        if (data.project.id) {
          this.$router.push('/projects/' + data.project.id)
        }

      } catch (e) {
        logException(e)
      }
    },
    async initItems () {
      if (this.tag.length === 0) return

      cancelSource = createCancelSource()

      try {
        const { data } = await this.$api.get('tags').search(this.tag, {
          cancelToken: cancelSource.token
        })

        const { tags } = data
        this.autocompleteTagItems = tags.map(item => {
          const name = JSON.parse(item.name)
          return { text: name.en }
        })

      } catch (e) {
        logException(e)
      }
    },
    async getData () {
      this.loadWorkflows()
      cancelSource = createCancelSource()

      try {
        const { data } = await this.$api.get('projects').count({
          cancelToken: cancelSource.token
        })

        Object.keys(data.phases).forEach(key => {
          this.phaseCounts[key] = data.phases[key]
        })
        this.activeProjects = data.active
        this.archivedProjects = data.archived

        if (this.activeProjects > 0 || this.archivedProjects > 0) {
          this.noProjects = false
        }

        if (this.$route.query.filter) {
          this.viewFilter = this.$route.query.filter
          //this.$refs.table.refresh()
        }

      } catch (e) {
        logException(e)
      }
      try {
        const { data } = await this.$api.get('user').checkIntroSteps()
        const {
          doneLeadForm,
          doneProposal,
          doneContract
        } = data

        this.doneLeadForm = doneLeadForm
        this.doneProposal = doneProposal
        this.doneContract = doneContract

        if (this.doneLeadForm && this.doneProposal && this.doneContract) {
          this.doneIntro = true
        }
      } catch (e) {
        logException(e)
      }

      this.loading = false
    },
    async checkIntroVideo () {
      let showModal = true
      if (this.user.me.companySettings.watchIntroVideos) {
        this.user.me.companySettings.watchIntroVideos.forEach(video => {
          if (video == 'intro') {
            showModal = false
          }
        })
      }

      if (showModal) {
        this.isShowIntroModal = true
      }
    },
    async checkIntroOverviewVideo () {
      let showModal = true
      if (this.user.me.companySettings.watchIntroVideos) {
        this.user.me.companySettings.watchIntroVideos.forEach(video => {
          if (video == 'projects-overview') {
            showModal = false
          }
        })
      }

      if (showModal) {
        this.isShowProjectOverviewModal = true
      }
    },
    async showGetStartedModal () {

      this.$modal.show('getStartedModal')
      try {
        const { data } = await this.$api.get('leadform').detail(this.user.company.id, 'get-started')
        this.form = data.form
        this.leadFormLoading = false
      } catch (e) {
        this.$toasted.global.api_error(e)
      }

    },
    saveLead () {
      this.$validator.validate().then(result => {
        if (result) {
          this.doSave()
        }
      })
    },
    async doSave (saveAndEditLater = false) {

      this.leadFormLoading = true

      try {
        const postData = {
          form: this.form,
          formId: this.form.id,
          companyId: this.user.company.id
        }

        const { data } = await this.$api.get('lead').submit(postData)
        this.$router.push('/projects/' + data.project_id)

      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
    async searchContact (input) {
      if (input.length < 3) { return [] }

      this.selectedContact = null
      try {
        const { data } = await this.$api.get('contacts').search(input)
        const { results } = data
        return results
      } catch (e) {
        logException(e)
      }
    },
    getContactResult (result) {
      return result.first_name + ' ' + result.last_name
    },
    searchContactClick (result) {
      this.selectedContact = result
    },
    showIntroModal () {
      this.isShowIntroModal = true
    },
    closeIntroModal () {
      this.isShowIntroModal = false
    },
    showProjectOverviewModal () {
      this.isShowProjectOverviewModal = true
    },
    closeProjectOverviewModal () {
      this.isShowProjectOverviewModal = false
    },
    async loadWorkflows () {
      const res = this.handleErrors(await getWorkflows())
      if (!res) return
      this.workflows = res.data.data.sort((a, b) => a.name > b.name ? 1 : -1)
    }
  },
}
</script>

<style>
  .modal-content-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 5px);
  }

  tr[data-color] td:first-of-type {
    border-left: 4px solid var(--row-color) !important;
  }

  tr td {
    position: static;
  }
</style>
